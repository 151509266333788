<template>
<div>
    <div
        id="kt_subheader"
        class="kt-subheader kt-grid__item"
    >
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <div class="kt-input-icon kt-input-icon--right kt-subheader__search mt-1">
                    <input
                        v-model="searchString"
                        type="text"
                        class="form-control"
                        placeholder="Filter..."
                    >
                    <span class="kt-input-icon__icon kt-input-icon__icon--right">
                        <span>
                            <i class="flaticon2-search-1" />
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pl-0 pb-5">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th colspan="3">
                                    User
                                </th>
                                <th colspan="1" style="width: 700px;">
                                    Attached Files
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(upload, idx) in filteredUploads"
                                :key="`upload_${idx}`"
                            >
                                <td style="width: 120px">
                                    <span
                                        class="school-ext-id-badge"
                                        title="Edit School"
                                        :class="[
                                            `color${Math.floor((upload.externalSchoolId.charCodeAt(upload.externalSchoolId.length - 1) + idx) % 10)}`,
                                        ]"
                                    >
                                        {{ upload.externalSchoolId }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <span class="kt-link kt-link--state kt-link--primary kt-font-bold">
                                            {{ upload.name }}
                                        </span>
                                        <div class="text-muted">
                                            {{ upload.email }}
                                        </div>
                                    </span>
                                </td>
                                <td>
                                    <span class="text-muted">
                                        {{ upload.lastModified }}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        v-for="(file, index) in upload.uploads"
                                        :key="`upload_${idx}_file_${index}`"
                                        class="ml-2 mr-2"
                                    >
                                        <button
                                            v-b-tooltip.hover="file.fileName || 'File name not found'"
                                            type="button"
                                            class="btn btn-label btn-label-primary btn-icon"
                                            @click.stop.prevent="downloadFile(file.path)"
                                        >
                                            <i class="fa fa-paperclip" />
                                        </button>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import { mapState } from 'vuex';
import moment from 'moment';
import * as network from '../../network';

export default {
    name: 'PublicUploads',
    components: { },
    data() {
        return {
            searchString: '',
            bucketObjects: [],
        };
    },
    computed: {
        ...mapState({
            deviceType: (state) => state.deviceType,
        }),
        formattedUploads() {
            const { bucketObjects } = this;
            return bucketObjects.reduce((acc, object) => {
                const { Key, LastModified } = object;
                const data = Key.split('/');
                const externalSchoolId = data[1] || '';
                const email = data[2] || '';
                const name = data[3] || '';
                const uploadId = data[4] || '';
                const fileName = data[6] || '';

                const idx = acc.findIndex((item) => item.uploadId === uploadId);
                if (idx === -1) {
                    acc.push({
                        externalSchoolId,
                        email,
                        name,
                        uploadId,
                        LastModified,
                        lastModified: moment(LastModified).format('MMM Do, YYYY h:mm:ss A'),
                        uploads: [{ path: Key, fileName }],
                    });
                } else {
                    acc[idx].uploads.push({ path: Key, fileName });
                }

                return acc;
            }, []);
        },
        filteredUploads() {
            const { searchString, formattedUploads } = this;
            return formattedUploads.filter((upload) => {
                if (!searchString) return true;
                return upload.uploads.some((item) => item.path.toLowerCase().includes(searchString.toLowerCase())) || upload.lastModified.toLowerCase().includes(searchString.toLowerCase());
            }).sort((a, b) => moment(b.LastModified).diff(moment(a.LastModified)));
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            const v = this;

            network.admin.listBucketObjects({ url: { bucketPath: 'public-secure-uploads' } }, (err, res) => {
                if (err) return v.showError(err);
                v.bucketObjects = res;
            });
        },
        downloadFile(path) {
            const v = this;

            network.admin.downloadBucketObject({ body: { bucketPath: path } }, (err, res) => {
                if (err) return v.showError(err);
                const { bucketUrl } = res;
                window.open(bucketUrl, '_blank');
            });
        },
    },
};
</script>

<style scoped>
tbody tr td {
    cursor: pointer;
    vertical-align: middle;
}
</style>
