var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "kt-subheader kt-grid__item",
        attrs: { id: "kt_subheader" },
      },
      [
        _c("div", { staticClass: "kt-container kt-container--fluid" }, [
          _c("div", { staticClass: "kt-subheader__main" }, [
            _c(
              "div",
              {
                staticClass:
                  "kt-input-icon kt-input-icon--right kt-subheader__search mt-1",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchString,
                      expression: "searchString",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "Filter..." },
                  domProps: { value: _vm.searchString },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchString = $event.target.value
                    },
                  },
                }),
                _vm._m(0),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pl-0 pb-5",
      },
      [
        _c("div", { staticClass: "kt-portlet" }, [
          _c("div", { staticClass: "kt-portlet__body" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-striped table-hover" }, [
                _vm._m(1),
                _c(
                  "tbody",
                  _vm._l(_vm.filteredUploads, function (upload, idx) {
                    return _c("tr", { key: `upload_${idx}` }, [
                      _c("td", { staticStyle: { width: "120px" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "school-ext-id-badge",
                            class: [
                              `color${Math.floor(
                                (upload.externalSchoolId.charCodeAt(
                                  upload.externalSchoolId.length - 1
                                ) +
                                  idx) %
                                  10
                              )}`,
                            ],
                            attrs: { title: "Edit School" },
                          },
                          [_vm._v(" " + _vm._s(upload.externalSchoolId) + " ")]
                        ),
                      ]),
                      _c("td", [
                        _c("span", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "kt-link kt-link--state kt-link--primary kt-font-bold",
                            },
                            [_vm._v(" " + _vm._s(upload.name) + " ")]
                          ),
                          _c("div", { staticClass: "text-muted" }, [
                            _vm._v(" " + _vm._s(upload.email) + " "),
                          ]),
                        ]),
                      ]),
                      _c("td", [
                        _c("span", { staticClass: "text-muted" }, [
                          _vm._v(" " + _vm._s(upload.lastModified) + " "),
                        ]),
                      ]),
                      _c(
                        "td",
                        _vm._l(upload.uploads, function (file, index) {
                          return _c(
                            "span",
                            {
                              key: `upload_${idx}_file_${index}`,
                              staticClass: "ml-2 mr-2",
                            },
                            [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      value:
                                        file.fileName || "File name not found",
                                      expression:
                                        "file.fileName || 'File name not found'",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  staticClass:
                                    "btn btn-label btn-label-primary btn-icon",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.downloadFile(file.path)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-paperclip" })]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "kt-input-icon__icon kt-input-icon__icon--right" },
      [_c("span", [_c("i", { staticClass: "flaticon2-search-1" })])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { colspan: "3" } }, [_vm._v(" User ")]),
        _c("th", { staticStyle: { width: "700px" }, attrs: { colspan: "1" } }, [
          _vm._v(" Attached Files "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }